import React from 'react'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer footer-background has-text-white-ter">
        <div className="has-text-centered footer-background has-text-white-ter">
          <div className="container footer-background has-text-white-ter">
            <div className="columns">
              <div className="column is-2">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        <FormattedMessage id="go_announcers" />
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        <FormattedMessage id="go_about" />
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                      <FormattedMessage id="go_contact" />
                      </Link>
                    </li>
                  </ul>
                  <a title="Facebook" href="https://facebook.com/voiceoverslt/" target="_blank"
                      rel="noopener">
                      <img
                        src={facebook}
                        alt="Facebook"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                </section>
              </div>
              <div>
              <div className="column is-10 content has-text-centered">
                <img
                  src={logo}
                  alt="Voiceovers"
                  style={{ width: '14em', height: '10em' }}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
