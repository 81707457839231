import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import background from "../img/theme.png"
import voiceovers_lt from '../img/voiceovers_lt.png'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div id="app">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon.png`}
          sizes="48x48"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div className="content"
        style={
          typeof window !== 'undefined' && window.innerWidth > 780 ? {
            backgroundImage: `url(${background})`,
            minHeight: `-webkit-calc(100vh - 354px)`,
            minHeight: `-moz-calc(100vh - 354px)`,
            minHeight: `calc(100vh - 354px)`} 
            : 
            {background: `#5e7898`}}
        >
        <div className="bckg-voiceovers" style={{
        backgroundImage: `url(${voiceovers_lt})`
        }}>
          <div>
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
