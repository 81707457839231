import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import enFlag from '../img/gb_3d.png'
import ltFlag from '../img/lt_3d.png'

const languageName = {
  lt: "Lietuvių",
  en: "English",
}

const Language = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                filter: currentLocale === language ? `` : `grayscale(30%)`,
                margin: 10,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
            >
              <img 
                style={{
                  height: 16,
                }}
                src={language === 'en' ? enFlag : ltFlag}
                alt={languageName[language]}
                title={languageName[language]} />
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
